import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Para } from "../components/basic"
import DownloadWhitePaperButton from "../components/downloadWhitePaperButton";

export default () => (
  <Layout hideHeader alwaysDarkLogo>
    <SEO />
    <div
      style={{
        marginTop: "100px",
        textAlign: "center",
      }}
    >
      <Para style={{textAlign: "center"}}>Click below to download white paper</Para>
      <DownloadWhitePaperButton />
    </div>
  </Layout>
)
