import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Button } from "./basic"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(
            filter: {
              name: { eq: "whitepaper" }
              sourceInstanceName: { eq: "content" }
            }
          ) {
            edges {
              node {
                childConfigJson {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { url } = data.allFile.edges[0].node.childConfigJson
        return (
          <a href={url}>
            <Button gradientBg>Download</Button>
          </a>
        )
      }}
    />
  )
}
